<template>
  <div style="width: 100%;">
    <div class="d-flex flex-column">
      <h3>Inventory</h3>
      <h1>{{this.instruction}}</h1>
      <span>
        <v-progress-circular
          indeterminate
          color="green"
          v-if="loading"
      ></v-progress-circular>
      </span>
      <span v-if="!product && this.instruction === this.steps[0]">
        <span class="d-flex flex-column">
          <v-card outlined>
            <v-card-text>
              <span class="d-flex flex-row align-center">
              </span>
              <span class="d-flex flex-row align-center justify-center">
                <v-text-field style="width: 100%;" v-model="productSearchTerm" @change="searchProduct()" :loading="loadingSearchProduct" label="Search Product" outlined></v-text-field>
              </span>
              <p v-if="noSearchResults">No search results were found.</p>
              <span v-if="productSearchResults&&productSearchResults.length>0">
                <span class="d-flex flex-row justify-center align-center">
                  <b>Found {{productSearchResults.length}} Search Results</b>
                  <v-btn color="info" style="margin-left: 10px;" @click="clearProductSearchResults">Clear</v-btn>
                </span>
                <div v-for="item in productSearchResults" :key="item.id">
                  <span class="d-flex flex-row justify-space-between" style="align-text: left; background-color: rgba(0,0,0,0.05); padding: 10px; margin-top: 6px; border-radius: 7px;">
                    <div class="d-flex flex-column justify-space-between">
                      <span class="d-flex flex-row justify-space-between">
                        <b style="text-align: left;">{{item.Brand?`${item.Brand.name} `:''}} {{item.name}}</b>
                      </span>
                      <span class="d-flex flex-column align-start">
                        <span><b>SP: ${{item.regularPrice}}</b>, <span v-if="isAllowed('product', 'viewCostPrice')">PP: ${{item.salePrice}}</span></span>
                        <span><b>QTY: {{item.ProductLocationJoins.find(x => x.userBranch===true)?item.ProductLocationJoins.find(x => x.userBranch===true).available:0}}</b><span v-if="getGlobalValue('showBranchQuantities')==='true'">[{{item.ProductLocationJoins.reduce((total, curr) => total+parseInt(curr.available), 0)}}]</span></span>
                        <!-- <p>{{item}}</p> -->
                        <span>ID: {{item.id}}, SKU:{{item.sku}}</span>
                      </span>
                    </div>
                    <v-btn fab color="success" @click="goToProduct(item)" x-small><v-icon>mdi-chevron-right</v-icon></v-btn>
                  </span>
                </div>
              </span>
            </v-card-text>
          </v-card>
        </span>
      </span> 
      <span v-if="product">
        <v-card outlined>
          <v-card-text>
            <span class="d-flex flex-row align-start justify-space-between">
              <span>
                <h3>{{product.name}}</h3>
                ID: {{this.product.id}} |  SKU: {{this.product.sku}}
<!--                <h5>In Stock: {{product.totalQuantity}}</h5>-->
<!--                <h5>Available: {{product.totalAvailable}}</h5>-->
                <!-- <h5 v-if="product.deliveryValues.length>0">Pending Delivery: {{product.deliveryValues[0].quantity}}</h5> -->
              </span>
              <v-btn @click="removeProduct" color="error" fab x-small><v-icon>mdi-close</v-icon></v-btn>
            </span>
            <hr>
            <br>
            <span v-if="product">
              <v-radio-group
                  label="I want to..."
                  v-model="product.changeType"
                  @change="productLocationChange"
                  dense
                  column
              >
                <v-radio
                    :key=i v-for="(type, i) of computedQuantityChangeTypes"
                    :label="type.name"
                    :value="type.value"
                />
              </v-radio-group>
              <span v-if="product.changeType!=='tempQuantity'">
<!--                <div v-for="(branch, i) of product.locations" :key="i">-->
<!--                  <span>-->
<!--                    <span class="d-flex flex-row">-->
<!--                      <b class="mr-1">{{branch.name}}</b>-->
<!--                    </span>-->
<!--                    <span v-for="(location, j) of branch.rooms" :key="j" md="6" class="d-flex flex-row">-->
<!--                      <span>-->
<!--                        <v-checkbox @change="toggleLocationQuantityEdit" v-model="location.enabled" label="" style="margin-top: 5px;"/>-->
<!--                      </span>-->
<!--                      <v-text-field dense outlined :min="0" :disabled="!location.enabled || product.changeType==='tempQuantity'" @change="changeQty(location, 'quantity')" :rules="quantityRules" class="mr-2" :label="`${location.name} QTY`" v-model="location.quantity" type="number"/>-->
<!--                    </span>-->
<!--                  </span>-->
<!--                </div>-->
                <v-radio-group
                    v-if="product.changeType"
                    label="What location do you want to change?"
                    v-model="locationData.locationId"
                    @change="productLocationChange"
                    dense
                    column
                >
                <v-radio
                    :key=i v-for="(location, i) of product.locations"
                    :label="location.name"
                    :value="location.locationId"
                />
              </v-radio-group>
              </span>
              <span class="text-center" v-if="locationData.locationId && product.changeType!=='tempQuantity'">
                <h2>Current: {{locationData.locationQuantity}}</h2>
                <h2>New: {{getNewQuantity()}}</h2>
              </span>
              <v-text-field v-if="locationData.locationId && product.changeType!=='tempQuantity'" dense outlined label="QTY" type='number' v-model="locationData.quantity"/>
              <v-checkbox
                  v-model="product.manageStock"
                  label="Manage Stock?"
                  outlined
                  class="mb-2"
                  dense
                  hint="Uncheck this if you want to ignore quantity constraints."
                  persistent-hint
                  v-if="product.changeType!=='tempQuantity'"
              />
<!--              <span v-if="product.changeType==='tempQuantity'">-->
<!--                <p class="red&#45;&#45;text text-pre-line">{{ "CALCULATED VALUE\nThis will auto-calculate the difference of already sold with the new quantity and fill in the new value.Managed Stock is also automatically enabled." }}</p>-->
<!--              </span>-->
<!--              <span v-if="product.changeType==='absolute'">-->
<!--                <p class="red&#45;&#45;text text-pre-line">{{ "OVERWRITTEN VALUE\nThis is used to overwrite the current value and assumes the current value as faulty.Stock reports would disregard the old value." }}</p>-->
<!--                <span>Current Value: {{calculateExcludedTotal()}}</span>-->
<!--                <br>-->
<!--                <span>New Value: {{calculateTotal()}}</span>-->
<!--              </span>-->
<!--              <span v-if="product.changeType==='relative'">-->
<!--                <p class="red&#45;&#45;text text-pre-line">{{ "ADJUSTED / CALCULATED VALUE\nThis is used to adjust the current value by the inputted value.This can be used to increment or decrement the current value." }}</p>-->
<!--                <span>Current Value: {{calculateExcludedTotal()}}</span>-->
<!--                <br>-->
<!--                <span>New Value: {{parseInt(calculateExcludedTotal())+parseInt(calculateTotal())}}</span>-->
<!--              </span>-->
              <v-row>
                <v-col>
                  <v-textarea outlined v-model="product.notes" label="Notes"/>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col class="d-flex flex-row justify-center">
                  <v-btn color="info" v-if="product.Product.metadata.updateLog && product.Product.metadata.updateLog.length>0" @click="locationsDialogShowLog=!locationsDialogShowLog">{{locationsDialogShowLog?'Hide':'Show'}} Logs</v-btn>
                </v-col>
              </v-row>
              <div v-if="product.Product.metadata.updateLog">
                <div v-if="locationsDialogShowLog">
                  <v-card v-for="(log, l) in product.Product.metadata.updateLog" :key="l" elevation="0" outlined class="mb-1">
                    <v-card-text>
                      <v-row>
                        <v-col class="d-flex flex-column">
                          <span v-if="log.notes">Notes: <b>{{log.notes}}</b></span>
                          <span>QTY: {{log.qtyBefore?log.qtyBefore:0}}<v-icon>mdi-arrow-right-bold</v-icon>{{log.qtyAfter}}</span>
                          <span>Reason: <b>{{quantityChangeTypes.find(x => x.value===log.changeType).name}}</b></span>
                        </v-col>
                        <v-col class="d-flex flex-column text-right">
                          <span>{{utils.parseDate(log.updatedAt, 2)}} {{utils.formatTime(log.updatedAt)}}</span>
                          <span>Modified By: <b>{{lookupUsername(log.userId)}}</b></span>
                          <span>Location: <b>{{lookupLocation(log.locationId)}}</b></span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </div>
              </div> -->
            </span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="!product.valid" block color="success" :loading="locationsDialogPending" @click="saveProductQuantities">Confirm</v-btn>
          </v-card-actions>
        </v-card>
      </span>
    </div>
    <v-snackbar style="margin-bottom: 60px;" v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import scanSystem from '../../plugins/scanSystem';
  import {mapGetters, mapMutations} from "vuex";

  export default {
    data () {
      return {
        instruction: "",
        quantityRules: [
          v => v>=0 || 'Must be more than or equal 0'
        ],
        deleteDialog: false,
        deleteConfirmed: true,
        loading: false,
        product: undefined,
        snackObj: {
          state: false,
          color: '',
          text: ''
        },
        imgObj: {
          isPhotoTaken: false,
          flash: false,
          addPhotoLoader: false,
          snapAndSaveLoader: false,
          photoToDisplay: '',
        },

        locationData: {
          quantity: 0,
          locationId: null,
          locationQuantity: 0
        },

        quantityChangeTypes: [
          {name: "Replace the quantity", value: "absolute"}, {name: "Add to the current quantity", value: "relative"}, {name: "Stop using temporary quantities", value: "tempQuantity"}
        ],
        steps: ["1. Scan a Barcode or Search Product", "Scan Received...", "2. Enter Quantities"],
        locationsDialogPending: false,
        locations: [],
        productSearchTerm: '',
        loadingSearchProduct: false,
        productSearchResults: [],
        noSearchResults: false
      }
    },
    async mounted(){
      try {
        let rooms = await axios.get(`${this.getEndpoint}/api/locations/groupNoSystem`);
        if(rooms.data.error) throw rooms.data.error
        this.locations = rooms.data.data;
        this.instruction = this.steps[0]
      // this.product = await this.lookupProduct('810036021012', 'SKU')
      // this.instruction = this.steps[1]
      // this.loading = false
      } catch (error) {
        console.log(error)
        this.snack(error)
      }
    },
    watch: {
      scanBus: {
        handler(){
          console.log("Order.vue: ScanBus Changed")
          if(!this.scanBus.data || this.scanBus.data == "" || this.scanBus.data == undefined){
            console.log("Watcher detected that scanbus was reset. Terminating handoff to handler.")
            return
          }
          if(this.scanBus.handler == 'inventory'){
            this.inventoryHandler()
          }
        },
        deep: true
      }
    },
    computed: {
      ...mapGetters(['getEndpoint', 'isAllowed', 'scanBus', 'getGlobalValue']),
      computedQuantityChangeTypes: function(){
        return this.quantityChangeTypes.filter(type => type.value!=='tempQuantity' || (type.value==='tempQuantity' && this.product.metadata.tempQuantity===true))
      }
    },
    methods: {
      ...mapMutations([
        'setScanBus',
        'resetScanBus',
      ]),
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async saveProductQuantities(){
        try{
          this.locationsDialogPending = true;
          if(!this.product) throw "No product selected."

          let parsedLocationsData = [];

          let selectedLocation = this.product.locations.find(x => x.locationId===this.locationData.locationId);
          if(!selectedLocation) throw "Location Data missing."
          let b = selectedLocation.branch;
          b.rooms = []
          b.rooms.push({
            locationId: selectedLocation.locationId,
            name: selectedLocation.name,
            quantity: this.locationData.quantity,
            enabled: true
          });

          parsedLocationsData.push(b);

          let obj = {
            id: this.product.id,
            type: this.product.type,
            locations: parsedLocationsData,
            changeType: this.product.changeType,
            notes: this.product.notes || '',
            manageStock: this.product.manageStock
          }
          console.log(obj)
          let res = await axios.post(`${this.getEndpoint}/api/products/updateLocations`, obj);
          if(res.data.error) throw res.data.error
          this.product.manageStock = obj.manageStock;
          this.snack("Quantities Updated");
          this.product=undefined;
          this.locationData.quantity=0;
          this.locationData.locationQuantity=0;
          this.clearProductSearchResults()
          let rooms = await axios.get(`${this.getEndpoint}/api/locations/groupNoSystem`);
          if(rooms.data.error) throw rooms.data.error
          this.locations = rooms.data.data;
          this.instruction = this.steps[0]
        }
        catch(error){
          console.error(error);
          this.snack(error.msg || error, "error");
        }
        finally{
          this.locationsDialogPending = false;
        }
      },
      toggleLocationQuantityEdit(){
        this.$forceUpdate();
      },
      async inventoryHandler(){
        try {
          this.loading = true
          this.instruction = this.steps[1]
          this.locationData = {
            quantity: 0,
            locationId: null,
            locationQuantity: 0
          }
          console.log("Inventory Handler Fired")
          let type = scanSystem.validateType(this.scanBus.data)
          let s = this.scanBus.data.replace(/\\\/\/\\=/,'')
          let id = (s.replace(`${type}-`, ''));
          if(type!='INV'&&type!='DN'&&type!='PO'&&type!='VSID'){
            let prod = await this.lookupProduct(id, type)
            if(!prod){
              console.log("Product could not be found at this time.")
              this.snack("Product not found.")
              this.instruction = this.steps[0]
            }else{
              this.product = prod
              await this.loadLocations()
              this.instruction = this.steps[2]
            }
          }
        } catch (error) {
          console.log(error)
          this.snack(error)
        } finally {
          this.setScanBus({data: null})
          console.log('ScanBus Cleared')
          this.loading = false
        }
      },
      calculateTotal(){
        let total = 0;

        for(let branch of this.product.locations){
          for(let room of branch.rooms){
            if(room.enabled) total+=room.quantity;
          }
        }

        return total;
      },
      calculateExcludedTotal(){
        let total = this.product.totalQuantity;

        for(let branch of this.product.locations){
          for(let room of branch.rooms){
            if(!room.enabled) total-=room.quantity;
          }
        }

        return total;
      },
      getNewQuantity(){
        console.log(this.product.changeType)
        if(this.product.changeType==='tempQuantity'){
          return null;
        }
        if(this.product.changeType==='absolute'){
          return isNaN(parseInt(this.locationData.quantity))?0:parseInt(this.locationData.quantity);
        }
        if(this.product.changeType==='relative'){
          return isNaN(parseInt(this.locationData.locationQuantity)+parseInt(this.locationData.quantity))?0:parseInt(this.locationData.locationQuantity)+parseInt(this.locationData.quantity);
        }
        return null
      },
      changeQty(location, field='quantity'){
        if(location[field]>=0){
          location[field]=parseInt(location[field]);
          this.$forceUpdate();
        }
        this.productLocationChange();
      },
      productLocationChange(){
        let found = this.product.locations.find(x => x.locationId===this.locationData.locationId);
        if(found){
          this.locationData.locationQuantity = found.quantity || 0;
        }
        this.product.valid = this.checkProductLocationDialog();
        this.$forceUpdate();
      },
      checkProductLocationDialog(){
        if(!this.product) return false;
        if(!this.product.changeType) return false;
        if(this.product.changeType==='tempQuantity') return true;
        if(!this.locationData.locationId) return false;
        return true;
      },
      async lookupProduct(s, type){
        try {
          let res;
          if(type === "SKU")
            res = await axios.get(`${this.getEndpoint}/api/products/bySKU/${s}`)
          if(type === "PL")
            res = await axios.get(`${this.getEndpoint}/api/products/${s}`)
          if(res.data.error) throw res.data.error
          if(!res.data.data) throw 'External barcode is not in VIMS DB.'
          return res.data.data
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      removeProduct(){
        this.instruction = this.steps[0]
        this.product = undefined
        this.variableProductLocationRadio = null;
        this.clearProductSearchResults()
        this.locationData = {
          quantity: 0,
          locationId: null,
          locationQuantity: 0
        }
      }, 
      async loadLocations(){
        try{
          if(!this.product) return
          let getLocations = () => {
            let locations = [];
            for(let branch of this.locations){
              let b = {
                id: branch.id,
                name: branch.name,
                rooms: [],
                available: 0,
                quantity: 0
              }
              for(let room of branch.rooms){
                let obj = {
                  locationId: room.id,
                  name: branch.name+" - "+room.name,
                  quantity: 0,
                  enabled: false,
                  branch: b
                }
                locations.push(obj);
              }
            }
            return locations;
          }
          
          this.product.totalQuantity = 0;
          this.product.totalAvailable = 0;
          this.product.locations = getLocations();
          this.product.changeType = null;
          this.product.notes = '';
          this.product.valid = false;
  
          let prj = await axios.get(`${this.getEndpoint}/api/products/${this.product.id}/onlyLocations`);
          if(prj.data.error) throw prj.data.error
  
          if(prj.data.data.metadata.updateLog){
            this.product.metadata.updateLog = prj.data.data.metadata.updateLog;
          }
  
          let branches = prj.data.data.ProductLocationJoins;
          this.product.deliveryValues = prj.data.data.DeliveryJoins;
          for(let branch of branches){
            for(let room of branch.rooms){
              let f2 = this.product.locations.find(x => x.locationId===room.locationId);
              if(f2){
                f2.quantity = room.quantity;
                this.product.totalQuantity+=room.quantity;
              }
            }
          }
        }
        catch(error){
          console.error(error);
          this.snack(error.msg || error, "error");
        }
      },
      async searchProduct(){
        try {
          if(this.productSearchTerm){
            this.loadingSearchProduct = true;
            let res = await axios.get(`${this.getEndpoint}/api/products/searchByAllVariationsNoLimit?val=${encodeURIComponent(this.productSearchTerm)}`);
            if(res.data.error) throw res.data.error
            this.productSearchResults = res.data.data
            if(this.productSearchResults.length == 0) this.noSearchResults = true
            else this.noSearchResults = false
          }
        } catch (error) {
          console.error(error)
          this.snack(error, "error")
        }finally {
          this.loadingSearchProduct = false;
        }
      },
      clearProductSearchResults(){
        this.productSearchResults = []
        this.productSearchTerm = ""
        this.noSearchResults = false
      },
      watchInput(){
        if(this.productSearchTerm.length == 0) this.noSearchResults = false
      },
      async goToProduct(prod){
        this.product = prod
        await this.loadLocations()
        this.locationData = {
          quantity: 0,
          locationId: null,
          locationQuantity: 0
        }
        this.instruction = this.steps[2]
      }  
    },
  }
</script>
